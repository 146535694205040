import styled from '@emotion/styled'
import { graphql } from 'gatsby'
import Image from 'gatsby-image/withIEPolyfill'
import React, { useContext, useEffect, useState } from 'react'

import GallerySection from '../../components/CarryAll/GallerySection'
import DropdownSelect from '../../components/DropdownSelect'
import ExploreSection from '../../components/Landing/ExploreSectionPhone'
import Layout from '../../components/Layout'
import PersonliseModal from '../../components/Personalisation'
import Reviews from '../../components/Reviews'
import SelectorSection from '../../components/SelectorSection'
import SEO from '../../components/seo'
import StoreContext from '../../context/StoreContext'
import fbq from '../../helper/fpq'
import usePersonalisationParams from '../../helper/usePersonalisationParams'
import useProductData from '../../helper/useProductData'
import useTracking from '../../helper/useTracking'
import Panels from './Panels'
import PetPersonliseModal from '../../components/PersonalisationDog'


function isEmpty(obj) {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false
  }
  return true
}

const LandingContainer = styled.div`
  min-height: 100vh;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-around;
  @media (min-width: 763px) {
    flex-direction: row;
    padding: 64px 96px;
    padding-top: 64px;
    margin-top: 114px;
  }
`

const colorNamesText = [
  'Black', //navy
  'Navy', //charcoal
  'Forest', //Forest
  'Taupe', //monochrome,
]

const count = ['1', '2', '3', '4']

const skus = [
  ['IPC-PRO01-BLA', 'IPC-PRO02-NAV', 'IPC-PRO03-FOR', 'IPC-PRO04-TAU'],
]

const PhoneCase = ({ data, pageContext, location }) => {
  const context = useContext(StoreContext)
  let { currency, setScroll, addVariantToCart, openCart } = context

  let [selectedColor, setSelectedColor] = useState(0)
  let [personaliseOpen, setPersonaliseOpen] = useState(undefined)
  let [labelData, setLabelData] = useState({})

  let [petLabelData, setPetLabelData] = useState({})
  let [petPersonaliseOpen, setPetPersonalisationOpen] = useState(undefined)
  const petPersonalised = isEmpty(petLabelData)

  const personalised = isEmpty(labelData)

  const {
    title,
    price,
    hasPersonalisation,
    freePersonalisation,
    description,
    overview,
    specs,
    reviewId,
    shopifyIds,
    fbIDs,
    oosData,
  } = useProductData(data.allCockpitProducts, !(!personalised || !petPersonalised))
  usePersonalisationParams(location.search, setLabelData, setPersonaliseOpen)

  const [trackAddToCart, updateItem] = useTracking({
    title: 'iPhone 12 / 12 Pro',
    id: fbIDs && fbIDs[selectedColor] ? fbIDs[selectedColor].split('_')[2] : '',
    imageUrl: '',
    url: 'https://july.com/travel-accessories/personalised-magsafe-iphone-12-pro-max-leather-case/',
    price: parseInt((price?.match(/\d+/g) || []).join('')),
    sku: skus[selectedColor],
  })

  useEffect(() => {
    updateItem({
      title: 'iPhone 12 / 12 Pro',
      id:
        fbIDs && fbIDs[selectedColor] ? fbIDs[selectedColor].split('_')[2] : '',
      price: parseInt((price?.match(/\d+/g) || []).join('')),
      url: 'https://july.com/travel-accessories/personalised-magsafe-iphone-12-pro-max-leather-case/',
      imageUrl: '',
      sku: skus[selectedColor],
      fb_id: fbIDs && fbIDs[selectedColor],
      variant: colorNamesText[selectedColor],
    })
  }, [selectedColor, fbIDs])

  const images_names = colorNamesText.map((color_val) =>
    count.map((count_val) => {
      return `${color_val}${count_val}`
    })
  )
  const images = images_names.map((second) => [
    data.Camp1,
    ...second.map((name) => data[name]),
    // data.Leather1,
    // data.Leather2,
    // data.Leather3,
    data.Leather4,
  ])

  useEffect(() => {
    setScroll(!personaliseOpen)
    if (personaliseOpen === false && window.innerWidth < 900) {
      setTimeout(() => {
        const titleDiv = document.getElementById('selector-top')
        const style = getComputedStyle(titleDiv)
        window.scrollTo(0, titleDiv.offsetTop - parseInt(style.marginTop))
      }, 10)
    }
  }, [personaliseOpen, labelData, setScroll])

  const handleAddToCart = async (e) => {
    e.preventDefault()

    let carryOnVarients = shopifyIds
    let backendLabelData = {}

    if (!personalised) {
      backendLabelData = { ...labelData, font: labelData.font.toLowerCase() }
    }

    if (!petPersonalised) {
      backendLabelData = { 
        position: petLabelData.position,
        pets: btoa(JSON.stringify(petLabelData.pets.map(pet => {
          return {
            name: pet.name,
            signedUrl: pet.signedUrl
          }
        })))
      }
    }


    await addVariantToCart(carryOnVarients[selectedColor], 1, {
      ...backendLabelData,
    })

    fbq(currency, 'AddToCart', {
      content_name: 'iPhone 12 / 12 Pro',
      content_category: 'Luggage',
      content_ids: [fbIDs && fbIDs[selectedColor]],
      content_type: 'product',
      value: parseInt((price?.match(/\d+/g) || []).join('')),
      currency:
        context.language
          .replace('root', 'au')
          .replace('global', 'us')
          .toUpperCase() + 'D',
    })
    trackAddToCart()

    openCart()
  }

  const [selectedOption, setSelectedOptions] = useState(0)
  const dropdownOptions = [
    {
      id: 1,
      name: 'iPhone 12 / 12 Pro',
      secondary: '',
      link: '/travel-accessories/personalised-magsafe-iphone-12-pro-leather-case/',
    },
    {
      id: 2,
      name: 'iPhone 12 Mini',
      secondary: '',
      link: `/travel-accessories/personalised-magsafe-iphone-12-mini-leather-case`,
    },
    {
      id: 3,
      name: 'iPhone 12 Max',
      secondary: '',
      link: `/travel-accessories/personalised-magsafe-iphone-12-pro-max-leather-case/`,
    },
  ]

  return (
    <>
      <SEO
        title={data.SEO.title.value}
        description={data.SEO.description.value}
        cannonical_url={data.SEO.cannonical_url.value}
        og_image={
          data.SEO?.TEST_banner_image?.value?.childImageSharp?.fixed?.srcWebp
        }
      />
      <div
        style={{
          display: personaliseOpen ? 'block' : 'none',
          height: '100vh',
        }}
      >
        {personaliseOpen && (
          <div
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              zIndex: 999999,
            }}
          >
            <PersonliseModal
              handleSave={(data) => {
                setPersonaliseOpen(false)
                setLabelData(data)
              }}
              handleClose={() => setPersonaliseOpen(false)}
              initialValues={labelData}
              selectedCaseColor={selectedColor}
              setSelectedCaseColor={setSelectedColor}
              selectedCase="iphone-classic"
            />
          </div>
        )}
      </div>
      <div
        style={{
          display: petPersonaliseOpen ? 'block' : 'none',
          height: '100vh',
        }}
      >
        {petPersonaliseOpen && (
          <div
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              zIndex: 999999,
            }}
          >
            <PetPersonliseModal
              handleSave={(data) => {
                setPetPersonalisationOpen(false)
                setPetLabelData(data)
              }}
              handleClose={() => setPetPersonalisationOpen(false)}
              initialValues={petLabelData}
              selectedCaseColor={selectedColor}
              setSelectedCaseColor={setSelectedColor}
              selectedCase="iphone-classic"
            />
          </div>
        )}
      </div>

      <div style={{ display: personaliseOpen ? 'none' : 'block' }}>
        <Layout
          facebookCaseId={fbIDs && fbIDs[selectedColor]}
          location="product"
          context={context}
        >
          <LandingContainer>
            <GallerySection
              defaultImage={3}
              selectedColor={selectedColor}
              images={images[selectedColor]}
              videosBefore={true}
              videos={[
                {
                  video: 'product-1.mp4',
                  thumbnail: data.Video1,
                },
                {
                  video: 'product-2.mp4',
                  thumbnail: data.Video2,
                },
              ]}
              hasPersonalisation={true}
            />
            <SelectorSection
              name={title}
              price={price}
              reviewId={reviewId}
              freePersonalisation={freePersonalisation}
              noSet={true}
              phoneCase={true}
              oosData={oosData}
              outline={description}
              outlineSub={overview}
              details={specs}
              aboveAddTocart={
                <>
                  <div style={{ marginBottom: 32 }}>
                    <DropdownSelect
                      selected={selectedOption}
                      optionsList={dropdownOptions}
                      setValue={setSelectedOptions}
                    />
                  </div>
                </>
              }
              handleAddToCart={handleAddToCart}
              selectedColor={selectedColor}
              setSelectedColor={setSelectedColor}
              handleBefore={() => {}}
              lifetime={true}
              isLuggage={true}
              setPersonaliseOpen={setPersonaliseOpen}
              labelData={labelData}
              personalised={personalised}

              setPetPersonalisationOpen={setPetPersonalisationOpen}
              petLabelData={petLabelData}
              petPersonalised={petPersonalised}

            />
          </LandingContainer>
          {/* <Reviews productId="4711753973817"></Reviews> */}
          <Panels setPersonaliseOpen={setPersonaliseOpen} />
          <ExploreSection />
        </Layout>
      </div>
    </>
  )
}
export const query = graphql`
  query {
    SEO: cockpitPage(
      cannonical_url: {
        value: {
          eq: "/travel-accessories/personalised-magsafe-iphone-12-pro-leather-case"
        }
      }
    ) {
      title {
        value
      }
      description {
        value
      }
      cannonical_url {
        value
      }
      keywords {
        value
      }
      category {
        value
      }

      TEST_banner_image {
        value {
          childImageSharp {
            fixed(width: 1200, height: 630) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
      }
    }
    allCockpitProducts(
      filter: {
        handle: {
          value: {
            eq: "/travel-accessories/personalised-magsafe-iphone-12-pro-leather-case"
          }
        }
      }
    ) {
      edges {
        node {
          handle {
            value
          }
          title {
            value
          }
          price {
            value
          }
          price_personalised {
            value
          }
          published {
            value
          }
          has_personalisation {
            value
          }
          free_personalisation {
            value
          }
          description {
            value
          }
          overview {
            value
          }
          specs {
            value
          }
          review_id {
            value
          }
          shopify_ids {
            value {
              data
            }
          }
          fb_ids {
            value {
              data
            }
          }
          lang
          oosDetails {
            value {
              data
            }
          }
        }
      }
    }

    Camp1: file(relativePath: { eq: "phone-new/pro.jpg" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    Leather1: file(relativePath: { eq: "phone-new/close-up/2.jpg" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Leather2: file(relativePath: { eq: "phone-new/close-up/4.jpg" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Leather3: file(relativePath: { eq: "phone-new/close-up/3.jpg" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Leather4: file(relativePath: { eq: "phone-new/close-up/1.jpg" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    Video1: file(relativePath: { eq: "phone-new/product-1.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Video2: file(relativePath: { eq: "phone-new/product-2.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    Black1: file(relativePath: { eq: "phone-new/ecom/classic/black/0.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Navy1: file(relativePath: { eq: "phone-new/ecom/classic/navy/0.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Forest1: file(relativePath: { eq: "phone-new/ecom/classic/forest/0.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Taupe1: file(relativePath: { eq: "phone-new/ecom/classic/taupe/0.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    Black2: file(relativePath: { eq: "phone-new/ecom/classic/black/1.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Navy2: file(relativePath: { eq: "phone-new/ecom/classic/navy/1.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Forest2: file(relativePath: { eq: "phone-new/ecom/classic/forest/1.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Taupe2: file(relativePath: { eq: "phone-new/ecom/classic/taupe/1.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    Black3: file(relativePath: { eq: "phone-new/ecom/classic/black/2.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Navy3: file(relativePath: { eq: "phone-new/ecom/classic/navy/2.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Forest3: file(relativePath: { eq: "phone-new/ecom/classic/forest/2.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Taupe3: file(relativePath: { eq: "phone-new/ecom/classic/taupe/2.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    Black4: file(relativePath: { eq: "phone-new/ecom/classic/black/3.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Navy4: file(relativePath: { eq: "phone-new/ecom/classic/navy/3.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Forest4: file(relativePath: { eq: "phone-new/ecom/classic/forest/3.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Taupe4: file(relativePath: { eq: "phone-new/ecom/classic/taupe/3.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`

export default PhoneCase
